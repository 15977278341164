import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Alert } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  const [npFields, handleNpFieldChange] = useFormFields({
    newPassword: "",
    confirmPassword: ""
  });

  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [user, setUser] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const res = await Auth.signIn(fields.email, fields.password);
      if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(res);
        setNewPasswordRequired(true);
      } else {
         userHasAuthenticated(true);     
      }
      setIsLoading(false);
     
    } catch (e) {
      onError(e);
      setIsLoading(false);
      console.log("error- " + e);
    }
  }

  const handleChangePasswordSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg(false);
    if(npFields.newPassword !== npFields.confirmPassword) {
      setErrorMsg("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      await Auth.completeNewPassword(user, npFields.newPassword);
      userHasAuthenticated(true);
      setIsLoading(false);  
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
      setIsLoading(false);
    }
  };

  if(newPasswordRequired){
    return (
      <div className="Login">
        <form onSubmit={handleChangePasswordSubmit}>
        <p>
          Before we begin, we require you to set a new password for
          this account.
        </p>
        {errorMsg && (
          <Alert variant="danger">
            <b>Error!</b> {errorMsg}
          </Alert>
        )}
        <FormGroup controlId="newPassword">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            type="password"
            value={npFields.newPassword}
            onChange={handleNpFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword">
          <ControlLabel>Confirm New Password</ControlLabel>
          <FormControl
            type="password"
            value={npFields.confirmPassword}
            onChange={handleNpFieldChange}
          />
        </FormGroup>
        <LoaderButton type="submit" isLoading={isLoading}>
          Change Password
        </LoaderButton>
      </form>
    </div>
    )
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}
