import React, { useState } from "react";
import TtsForm from "../containers/TtsForm";
import { Button } from "react-bootstrap";
import log from 'loglevel';


export default function CustomPagination(props) {

    //calculate amount of pages given data to display length
    const [pages, setPages] = useState([Math.round(props.fileArray.length / props.data.dataLimit)]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNeighbours, setPageNeighbours] = useState(1);
    const LEFT_PAGE = "<";
    const RIGHT_PAGE = ">";

    function getData(val) {
        log.info(val);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    //relevent slice of data from data array 
    const getPaginatedData = () => {
        const startIndex = currentPage * props.data.dataLimit - props.data.dataLimit;
        const endIndex = startIndex + props.data.dataLimit;
        let currentDataSlice = props.fileArray.slice(startIndex, endIndex);
        //log.info("returned paginated sliced array data: " + ret);
        if (currentDataSlice === undefined){
            throw new Error("Pagination Error");
        }
        log.info("pagination returns: " + JSON.stringify(currentDataSlice));
        return currentDataSlice;
    };

    //Helper method for creating a range of numbers
    //range(1, 5) => [1, 2, 3, 4, 5] 
    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    }

    /**
    * Let's say we have 10 pages and we set pageNeighbours to 2
    * Given that the current page is 6
    * The pagination control will look like the following:
    * (1) < {4 5} [6] {7 8} > (10)    *
    * (x) => terminal pages: first and last page(always visible)
    * [x] => represents current page
    * {...x} => represents page neighbours
    */
    function fetchPageNumbers() {
        const totalPages = Math.ceil(props.fileArray.length / props.data.dataLimit);
        //totalNumbers: the total page numbers to show on the control      
        const totalNumbers = (pageNeighbours * 2) + 3;
        //totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
        const totalBlocks = totalNumbers + 2;
        log.info(props.fileArray.length + "/ " + props.data.dataLimit);
        log.info("total pages in fetch: " + totalPages);
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            // hasLeftSpill: has hidden pages to the left
            const hasLeftSpill = startPage > 2;
            // hasRightSpill: has hidden pages to the right
            const hasRightSpill = (totalPages - endPage) > 1;
            // spillOffset: number of hidden pages either to the left or to the right
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = ["<", ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, ">"];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = ["<", ...pages, ">"];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }
        log.info("returning pagination array: " + range(1, totalPages));
        return range(1, totalPages);
    }

    function handleMoveLeft(evt) {
        evt.preventDefault();
        setCurrentPage(Math.max(currentPage - (pageNeighbours * 2) - 1, 0));
    }

    function handleMoveRight(evt) {
        evt.preventDefault();
        let frstExpr = currentPage + (pageNeighbours * 2) + 1;
        let maxPageLen = Math.ceil(props.fileArray.length / props.data.dataLimit);
        setCurrentPage(Math.min(frstExpr, maxPageLen));
    }

    function clearDataButtonPress() {
        if (window.confirm("Do you really wish to clear all the text to speech data currently displayed as a list? This can't be undone.")) {
            props.clearLocalStorage();
            window.location.reload();
        }
    }

    //buttons for pagination
    return (
        <div>

            <ul style={{ padding: 0 }}>
                <span style={{ fontSize: 12 }}>Page:</span>
                {fetchPageNumbers().map((page, index) => {
                    if (page === LEFT_PAGE){
                        return (
                            <Button bsStyle="link" key={index} onClick={handleMoveLeft} className="page-item">
                                {"<<"}
                            </Button>
                        );
                    }
                    if (page === RIGHT_PAGE){
                        return (
                            <Button bsStyle="link" key={index} onClick={handleMoveRight} className="page-item">
                                {">>"}
                            </Button>
                        );
                    }
                    return (
                        <Button bsStyle="link" key={index} onClick={changePage} className={`page-item${currentPage === page ? ' active' : ''}`} >
                            {page}
                        </Button>
                    );
                })}
                <div style={{ float: 'right' }}>
                    <Button bsStyle="success" style={{ margin: 0 }} onClick={clearDataButtonPress}> Clear data </Button>
                </div>
            </ul>
            <div className="dataContainer">
                {getPaginatedData().map((line, idx) => (
                    <TtsForm key={line[0].toString()}
                        myProp={{ Language: props.data.Language, Voice: props.data.Voice, TTSInput: line[1], FileName: line[0], VoiceQuality: props.VoiceQuality}}
                        pollyObj={props.pollyObj}
                        sendData={getData}
                        onAddString={props.onAddString}
                        updateArray={props.updateArray}
                        deleteElem={() => props.deleteElem(idx)}
                        addElem={props.addElem}
                        arrayIndex={idx}
                        updateFileName={props.updateFileName}
                        elementCount={props.fileArray.length}
                    />
                ))}
            </div>

        </div>
    );

}