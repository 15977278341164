//https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
  //These language codes are W3C language identification tags (ISO 639-3 for the language name and ISO 3166 for the country code).

export const langDictionary = {
  "Arabic": "arb",
  "Arabic (Gulf)": "ar-AE",
  "Catalan": "ca-ES",
  "Chinese (Cantonese)": "yue-CN",
  "Chinese (Mandarin)": "cmn-CN",
  "Danish": "da-DK",
  "Dutch (Belgian)": "nl-BE",
  "Dutch": "nl-NL",
  "English (Australian)": "en-AU",
  "English (British)": "en-GB",
  "English (Indian)": "en-IN",
  "English (New Zealand)": "en-NZ",
  "English (South African)": "en-ZA",
  "English (US)": "en-US",
  "English (Welsh)": "en-GB-WLS",
  "Finnish": "fi-FI",
  "French": "fr-FR",
  "French (Belgian)": "fr-BE",
  "French (Canadian)": "fr-CA",
  "Hindi": "hi-IN",
  "German": "de-DE",
  "German (Austrian)": "de-AT",
  "Icelandic": "is-IS",
  "Italian": "it-IT",
  "Japanese": "ja-JP",
  "Korean": "ko-KR",
  "Norwegian": "nb-NO",
  "Polish": "pl-PL",
  "Portuguese (Brazilian)": "pt-BR",
  "Portuguese (European)": "pt-PT",
  "Romanian": "ro-RO",
  "Russian": "ru-RU",
  "Spanish (European)": "es-ES",
  "Spanish (Mexican)": "es-MX",
  "Spanish (US)": "es-US",
  "Swedish": "sv-SE",
  "Turkish": "tr-TR",
  "Welsh": "cy-GB"
};

export const availableLanguages = Array.from(Object.keys(langDictionary));
