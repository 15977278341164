const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "gemini-app-2-api-dev-attachmentsbucket-4ej6c638juux"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://uez2k95bsl.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_c2yO2Mkd8",
    APP_CLIENT_ID: "6kijl4v0sf80ooqjf0d166ukib",
    IDENTITY_POOL_ID: "us-east-1:d93f4e33-ae0a-41cc-ad92-7f1fdbe251be"
  }
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-1v9w7kkxnznb7"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.serverless-stack.seed-demo.club/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mLbfKylhm",
    APP_CLIENT_ID: "mli2vaupiq3ga29m4698m6mrl",
    IDENTITY_POOL_ID: "us-east-1:4e377eff-0617-4098-b218-673490ffab8d"
  }
};
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
