import React, { useState } from "react";
import "./TtsForm.css";
import { Button, Row, Col, Panel, Tooltip, OverlayTrigger } from "react-bootstrap";
import { saveAs } from 'file-saver';
import log from 'loglevel';
import { BsDownload, BsFillPlusCircleFill, BsDashCircleFill } from "react-icons/bs";

export default function TtsForm(props) {

  const [textToTranslate, setTextToTranslate] = useState(props.myProp.TTSInput);
  const [audioURL, setAudioURL] = useState("");
  const [fileName, setFileName] = useState(props.myProp.FileName); //local file download name for each form
  const [displaySetting, setDisplaySetting] = useState(false);

  function updateTTSText(event) {
    if (event.target.value != null) {
      setTextToTranslate(event.target.value);
      props.updateArray(event.target.value, props.arrayIndex);
    } else {
      setTextToTranslate("");
      props.updateArray("");
    }
  }

  function previewSound() {
    let download = false;
    synthesiseSpeech(download);
    setDisplaySetting(true);
  }

  //downloads or sets up preview URL
  function synthesiseSpeech(download = true) {

    if(props.myProp.Voice === "No Voices Found"){
      log.info("Returning early as no voice found set for voice");
      alert("No voices found. Please select a voice for the text to speech input");
      return;
    }
    let polly = props.pollyObj;
    log.info("text to translate is " + textToTranslate);
    log.info(JSON.stringify( props.myProp));
    let params = {
      OutputFormat: 'mp3',
      Text: textToTranslate,
      VoiceId: props.myProp.Voice,
      SampleRate: '22050',
      TextType: 'text',
      Engine: props.myProp.VoiceQuality.toLowerCase()
    };
    log.info("engine selected: "  + props.myProp.VoiceQuality.toLowerCase());
    polly.synthesizeSpeech(params, function (err, data) {
      if (err) {
        log.error(err, err.stack);  
      }
      else {
        var uInt8Array = new Uint8Array(data.AudioStream);
        var arrayBuffer = uInt8Array.buffer;
        var blob = new Blob([arrayBuffer], { type: "audio/mpeg-3" });
        var url = URL.createObjectURL(blob);
        let mp3Name = (fileName.split(".")[0]) + ".mp3";
        if (download) {
          saveAs(url, mp3Name);
        } else {
          setAudioURL(url);
          setDisplaySetting(true);
        }
      }
    });
  }

  function AddListElement() {
    props.addElem(props.arrayIndex);
  }

  function RemoveListElement() {
    if (props.arrayIndex === 0 && props.elementCount === 1) {
      alert("can't remove the only item in the list!");
    } else {
      props.deleteElem();
    }
  }

  function SaveFileName() {
    props.updateFileName(fileName, props.arrayIndex);
  }

  function fileNameSet(event) {
    let newFileName = event.target.value
    setFileName(newFileName);
  };

  function setCharacterLength() {
    const characterAPILimit = 3000;
    if (textToTranslate != null) {
      let charCount = textToTranslate.length;
      if (charCount >= characterAPILimit) {
        alert("Character limit warning for file: " + fileName);
      }
      return charCount;
    } else {
      return 0;
    }
  }


  const updateFileTooltip = (
    <Tooltip id="updateFileName">
      You must press this button to save the renaming of the audio file
    </Tooltip>
  );

  let fileInputTextStyle = { height: 30, size: 20, width: "-webkit-fill-available", marginRight: 10, marginTop: 10 };
  let audioStyle = { width: "-webkit-fill-available", display: 'block' };
  //display a preview button, and only if this button is  clicked, show the audio player,replacing the preview, which will autoplay the audio.
  return (
    <Panel style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 15, paddingLeft: 15 }}>
      <Row>
        <Col xs={12}>
          <Button onClick={RemoveListElement} bsStyle="link" style={{ paddingLeft: 0 }}> {BsDashCircleFill()} </Button>
          <Button onClick={AddListElement} bsStyle="link"> {BsFillPlusCircleFill()}</Button>
          <Button variant="success" onClick={synthesiseSpeech} bsStyle="link" style={{ float: 'right' }}> {BsDownload()}</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <textarea
            name="textinput"
            onChange={updateTTSText}
            placeholder=""
            defaultValue={props.myProp.TTSInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <p id="charCount"> Character count: {setCharacterLength()}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={2} md={2}>
          <Button bsStyle="success" onClick={previewSound} style={{ marginTop: 10 }}>Preview</Button>
        </Col>
        <Col xs={3} sm={4} md={4}>
          <audio id="audioPlayback" type="audio/mp3" style={audioStyle} autoPlay controls src={audioURL}
            controlsList="nodownload">
            Your browser does not support the audio element.
          </audio>
        </Col>
        <Col xs={4} sm={3} md={4} >
          <input type="text" className="fileName" style={fileInputTextStyle} onChange={fileNameSet} value={fileName} />
        </Col>
        <Col xs={3} sm={3} md={2}>
          <OverlayTrigger placement="top" overlay={updateFileTooltip}>
            <Button onClick={SaveFileName} style={{ float: 'right', marginRight: 0 }}> Update file name</Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Panel>
  );

}