import React, { useState, useEffect } from "react";
import {
  PageHeader, ListGroup, Panel, Dropdown, MenuItem, Button, Row, Col,
  Tooltip, OverlayTrigger
} from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import Login from "../containers/Login";
import { Link } from "react-router-dom";
import CustomPagination from "../components/CustomPagination";
import "./Home.css";
import * as AWS from 'aws-sdk';
import { Auth } from "aws-amplify";
import { saveAs } from 'file-saver';
import JsZip from 'jszip';
import LoaderButton from "../components/LoaderButton";
import log from 'loglevel';
import * as CSV from 'papaparse';
import { availableLanguages, langDictionary } from "../libs/languages";

export default function Home() {

  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  //tts hooks:
  const [pollyVoices, setPollyVoices] = useState([]);
  const [currentVoice, setCurrentVoice] = useState("Salli-Female");
  const [currentLanugage, setCurrentLanguage] = useState("English (US)");
  const [processingTTS, setProcessingTTS] = useState(false);
  const [polly, setPolly] = useState(null);
  //file
  const [selectedFileText, setSelectedFileText] = useState("");
  const [fileAsJSON, setFileAsJSON] = useState([["file-output-name", "Get started by pasting in text here, or uploading a file!"]]);
  const pollyCharLimit = 3000;
  const [localStorageVal, setLocalStorageVal] = useState(false);
  const [voiceQuality, setVoiceQuality] = useState("Neural");

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      setIsLoading(false);
      initPollyAuth();
      getFileAsJSON();
    }
    onLoad();
  }, [isLoading, isAuthenticated, localStorageVal]);

  // return audio from given text,voice and pollyObject to call from
  async function generateAudio(text, voice, pollyObject) {

    if (pollyObject === null) {
      throw new Error('Polly object passed into generateAudio is null.')
    }
    let params = {
      OutputFormat: 'mp3',
      Text: text,
      VoiceId: voice.split("-")[0],
      SampleRate: '22050',
      TextType: 'text',
      Engine: voiceQuality.toLowerCase()
    };
    if (text.length > pollyCharLimit) {
      alert("Warning, character limit is " + pollyCharLimit + " the text beginning: " + text.substring(0, 20) + "... has " + text.length + " characters. ");
      return {}
    }
    return pollyObject.synthesizeSpeech(params).promise()
      .then(audio => {
        if (audio.AudioStream instanceof Buffer) {
          return audio.AudioStream
        }
        else throw new Error('AudioStream is not a Buffer.');
      });
  }

  function SetLocalStorageToArray(newArrayData) {
    localStorage.setItem('csvData', JSON.stringify(newArrayData));
  }

  function ClearLocalStorageArray() {
    log.warn("Calling local storage");
    localStorage.removeItem('csvData');
  }

  //removes clicked on child component at index 
  function deleteTTSFormElement(index) {
    let elementList = JSON.parse(JSON.stringify(fileAsJSON))
    let elemToDelete = elementList[index];
    log.info("to delete: " + elemToDelete + " , at given index: " + index);
    let filtered = elementList.filter(function (item) {
      return item !== elemToDelete;
    });
    log.info("filtered array is " + JSON.stringify(filtered));
    setFileAsJSON(filtered);
    SetLocalStorageToArray(filtered);
  }

  //downloads synchronously
  function DownloadCSV() {
    let csvFile = CSV.unparse(JSON.parse(JSON.stringify(fileAsJSON)));
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    ClearLocalStorageArray();
    saveAs(blob, "Gemini-csv-output.csv");
  }

  //adds clicked on child component at directly below index 
  function addTTSFormElement(index) {
    let elementList = JSON.parse(JSON.stringify(fileAsJSON))
    log.info("adding empty tts form at given index: " + index);
    log.info("before " + JSON.stringify(elementList));
    let newFileName = Date.now().toString();
    elementList.splice(index, -1, [newFileName, ""]);
    log.info("after " + JSON.stringify(elementList));
    setFileAsJSON(elementList);
    SetLocalStorageToArray(elementList);
  }

  //custom compare for elements in fileJsonArray ["filename","texttotranslate"]
  //for use objs.sort( compare ) sorting by the first element, "filename";
  function customCompare(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else if (a[0] < b[0]) {
      return -1;
    } else {
      return 1;
    }
  }

  //given a array of pairs ie fileJSON, ensures the first element in the pairs e.g  filename in [filename,filetext] are unique
  //this changes the order of the given array but preserves the pairs
  function createUniqueFileNameArray(array) {
    if (array == null || array.length < 1) {
      return [];
    }
    log.info("before: " + JSON.stringify(array));
    array.sort(customCompare);
    log.info("after sort: " + JSON.stringify(array));
    let counter = 0;
    let prev = array[0][0];
    let uniqueArray = [array[0]];
    for (let i = 1; i < array.length; i++) {
      if (prev === array[i][0]) {
        //change fileNameArray[i+1] to be a new unique name by appending count of occurance, and dont update previous element to compare on
        counter++;
        let newVal = array[i][0] + "(" + counter.toString() + ")";
        uniqueArray.push([newVal, array[i][1]]);
      } else {
        counter = 0;
        prev = array[i][0];
        uniqueArray.push(array[i]);
      }
    }
    log.info("final array of arrays unique= " + JSON.stringify(uniqueArray));
    return uniqueArray;
  }

  async function downloadAllFilesAsZip() {
    if (fileAsJSON && fileAsJSON.length >= 1) {
      setProcessingTTS(true);
      let zip = new JsZip();
      let outsidePolly;    //create polly object here for this operation to be used 
      //create and authorise polly
      AWS.config.region = 'us-east-1';
      await Auth.currentCredentials()
        .then(credentials => {
          let p = (new AWS.Polly({
            apiVersion: '2016-06-10',
            credentials: new AWS.Credentials(Auth.essentialCredentials(credentials))
          }));
          setPolly(p);
          outsidePolly = p;
          AWS.config.credentials = new AWS.Credentials(Auth.essentialCredentials(credentials));
        });
      for (let i = 0; i < fileAsJSON.length; i++) {
        let textToTranslate = fileAsJSON[i][1];
        let mp3Name = (fileAsJSON[i][0].split(".")[0]) + ".mp3";
        // //todo unique file name check. files are overwritten by default which is unwanted behaviuor
        let res = await generateAudio(textToTranslate, currentVoice, outsidePolly);
        log.info("name of file processing " + mp3Name);
        zip.file(mp3Name, res);
      }

      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          saveAs(content, "TTS-Gemini.zip");
        })
        .then(ClearLocalStorageArray())
        .finally(setProcessingTTS(false));
    } else {
      alert("No input file detected!");
    }
  }

  function updateElementText(updatedText, index) {
    let elementList = JSON.parse(JSON.stringify(fileAsJSON))
    let oldText = elementList[index];
    log.info("old text at index " + index + " " + oldText);
    elementList[index][1] = updatedText;
    setFileAsJSON(elementList);
    SetLocalStorageToArray(elementList);
  }

  function updateFileName(updatedName, index) {
    let elementList = JSON.parse(JSON.stringify(fileAsJSON))
    let oldText = elementList[index];
    log.info("old filename at index " + index + " " + oldText);
    let name = updatedName;
    //ignore element at current index
    const fileNames = elementList.map(e => e[0]);
    //removes current element
    fileNames.splice(index, 1);
    //ensure the filename is unique as its used for key 
    if (fileNames.includes(updatedName)) {
      name = Date.now();
      alert("File names must be unique. Setting filename to current timestamp.");
    }
    elementList[index][0] = name;
    setFileAsJSON(elementList);
    SetLocalStorageToArray(elementList);
  }

  //from the data returned in the polly query, sets the voices array values
  //excluding neural only voices if the engine is set to standard
  //and excluding standard only voices if set to neural
  function setVoicesArray(data, newVoiceQuality) {
    let voices = [];
    log.info("voices quality " + newVoiceQuality);
    voices = data.Voices.map(item => {
      log.info(JSON.stringify(item));
      //case: set to neural and voice has neural supported
      if (newVoiceQuality === "Neural" && item.SupportedEngines[0] === "neural") {
        return item.Id + "-" + item.Gender;
      }
      //case: set to standard and voice supports standard
      else if (newVoiceQuality === "Standard" && (item.SupportedEngines[0] === "standard" || item.SupportedEngines[1] === "standard")) {
        return item.Id + "-" + item.Gender;
      }
      // case: set to neural and neural NOT supported
      else if (newVoiceQuality === "Neural" && item.SupportedEngines[0] !== "neural") {
        log.info("no neural voice case met for: " + item.Id + "-" + item.Gender)
      }
      //case: set to standard and standard NOT supported, safe to ignore these
      else if (newVoiceQuality === "Standard" && !(item.SupportedEngines[0] === "standard" || item.SupportedEngines[1] === "standard")) {
        log.info("no standard voice case met for: " + item.Id + "-" + item.Gender)
      }
    });
    //filter out the null entries
    const newVoices = voices.filter(v => v != null);
    setPollyVoices(newVoices);
    if (newVoices.length > 0) {
      setCurrentVoice(newVoices[0]);
    } else {
      setCurrentVoice("No Voices Found");
    }
  }

  function updateVoicesDropdown(newVoiceQuality) {
    log.info("langauge given: " + currentLanugage);
    if (polly) {
      polly.describeVoices({
        LanguageCode: langDictionary[currentLanugage]
      }, function (err, data) {
        if (err) {
          log.error(err, err.stack);
        }
        else {
          setVoicesArray(data, newVoiceQuality);
        }
      })
    }
  }

  //on new language selected on dropdown get the relevent voices from the sdk
  function languageSelected(newLang) {
    setCurrentLanguage(newLang);
    let pollyConn = new AWS.Polly({
      apiVersion: '2016-06-10',
      credentials: AWS.config.credentials
    });
    pollyConn.describeVoices({
      LanguageCode: langDictionary[newLang]
    }, function (err, data) {
      if (err) {
        log.error(err, err.stack);
      }
      else {
        log.info("getting voice quality " + voiceQuality);
        setVoicesArray(data, voiceQuality);
      }
    })
  }

  function initPollyAuth() {
    if (polly == null) {
      log.info("Polly was null, making new");
      AWS.config.region = 'us-east-1';
      Auth.currentCredentials()
        .then(credentials => {
          let p = (new AWS.Polly({
            apiVersion: '2016-06-10',
            credentials: new AWS.Credentials(Auth.essentialCredentials(credentials)),
            region: 'us-east-1'
          }));
          setPolly(p);
          AWS.config.credentials = new AWS.Credentials(Auth.essentialCredentials(credentials));
          return p;
        })//get all voices in language code en-US (default region)
        .then((res) => {
          res.describeVoices({
            LanguageCode: "en-US"
          }, function (err, data) {
            if (err) {
              log.error(err, err.stack); // an error occurred
            }
            else {
              setVoicesArray(data, voiceQuality);
            }
          });
          setPolly(res);
          return res;
        });
      log.info("initalised polly");
    }
  }

  function fileUploaded(event) {
    if (selectedFileText !== "") {
      //show confirmation popup
    }
    setSelectedFileText(event.target.files[0]);
    showFile(event);
  };

  //turns input csv into array of [filename,filetext]
  function processCSVData(allText) {
    let parsedCSV = CSV.parse(allText).data;
    //this includes the first line (headers) and empty entries --> remove:
    let linesWithoutHeaders = parsedCSV.slice(1, parsedCSV.length);
    let csvArray = linesWithoutHeaders.filter(function (text) {
      return text[1] !== "";
    });
    //remove empty entries
    csvArray = csvArray.filter(function (el) {
      return el[0].length !== 0;
    });
    //read the file and ensure all file names are unique
    setFileAsJSON(createUniqueFileNameArray(csvArray));
  }

  async function showFile(e) {
    try {
      if (e.target.files[0]) {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
          const text = (e.target.result)          
          setSelectedFileText(text)
          //file format is 2 columns and the first row is the title of the columns.. eg FILE NAME; TEXT TO TRANSLATE
          processCSVData(text);
        };
        reader.readAsText(e.target.files[0])
      }
    } catch (e) {
      log.info("Caught error " + e);
      alert("please input a 2 column .csv/.txt file of format filename, text-to translate");
      setSelectedFileText("");
    }
  }

  //given data limit (data containers displayed per page) gives the amount of pages to populate buttons for
  function calculatePageLimit(dataLimit = 5) {
    return (Math.ceil(fileAsJSON.length / dataLimit))
  }

  function renderLander() {
    return (
      <div className="lander">
        {/* <div className="logo"></div> */}
        <h1>Login</h1>
        <div>
          <Login>
          </Login>
        </div>
        <Link to="/password">
          Reset Password
        </Link>
      </div>
    );
  }

  //tries to load cached data from local storage if variable data has been lost
  function getFileAsJSON() {
    setLocalStorageVal(false);
    if (fileAsJSON.length < 2) {
      log.info("filejson currently <2");
      let storageCSV = localStorage.getItem('csvData');
      log.log("storage csv: " + storageCSV)
      if (!storageCSV) {
        log.log("storageCSV is falsey");
        return [];
      } else {
        let parsed = JSON.parse(storageCSV);
        log.log("parsed: " + parsed[0]);
        setFileAsJSON(parsed);
        return parsed;
      }
    }
    return fileAsJSON;
  }

  function renderTTSForm() {
    return (
      <div>
        <div>
          {fileAsJSON.length > 0 ? (
            <CustomPagination
              data={{ Language: currentLanugage, Voice: currentVoice.split("-")[0], pageLimit: calculatePageLimit, dataLimit: 5 }}
              pollyObj={polly}
              updateArray={updateElementText}
              deleteElem={deleteTTSFormElement}
              fileArray={fileAsJSON}
              addElem={addTTSFormElement}
              updateFileName={updateFileName}
              clearLocalStorage={ClearLocalStorageArray}
              VoiceQuality={voiceQuality}
            >
            </CustomPagination>
          ) : (
            <div>
              <h1>Please input a file in .csv format: a comma delimited file with 2 columns, where each new element is on a new line.
              </h1>
              <h2>
                <a href="https://www.spreadsheetclass.com/google-sheets-export-csv/" > Guide to exporting google sheet as csv </a>
              </h2>
            </div>
          )}
        </div>
      </div>
    );
  }

  const downloadAllTooltip = (
    <Tooltip id="DLtooltip">
      Downloads all audio files as a zip.
    </Tooltip>
  );
  //Download your changes
  const saveAsCSVTooltip = (
    <Tooltip id="saveAsCSV">
      Download your changes as a .csv
    </Tooltip>
  );

  function toggleVoiceQuality() {
    let newVoiceQuality = "";
    log.info("TOGGLED voice quality, state before was " + voiceQuality);
    if (voiceQuality === "Neural") {
      newVoiceQuality = "Standard";
    } else {
      newVoiceQuality = "Neural";
    }
    setVoiceQuality(newVoiceQuality);
    updateVoicesDropdown(newVoiceQuality);
  }

  const minusPaddingStyle1 = { marginLeft: -13 };
  const minusPaddingStyle2 = { marginLeft: -8 };
  function renderTTS() {
    return (
      <div className="TTS">
        <PageHeader>Gemini TTS Tooling</PageHeader>
        <Panel style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 5, paddingLeft: 25 }}>
          <Row>
            <Col xs={12} md={7}>
              <Row>
                <Col xs={6} sm={2} md={3}>
                  <Row style={{ fontWeight: 'bold' }}> Language</Row>
                  <Dropdown id="dropdown - language">
                    <Dropdown.Toggle bsStyle="success" id="dropdown-basic" style={minusPaddingStyle1}>
                      {currentLanugage}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={minusPaddingStyle1}>
                      {availableLanguages.map((lang) => (
                        <MenuItem onClick={() => languageSelected(lang)} key={Math.random()}>
                          {lang}
                        </MenuItem>
                      ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6} sm={2} md={3}>
                  <Row style={{ fontWeight: 'bold' }}> Voice
                  </Row>
                  <Button
                    bsStyle="success" onClick={toggleVoiceQuality} style={{ marginLeft: -15 }}>
                    {voiceQuality}
                  </Button>
                  <Dropdown id="dropdown - voices" style={minusPaddingStyle2}>
                    <Dropdown.Toggle bsStyle="success" id="dropdown-basic" style={minusPaddingStyle2}>
                      {currentVoice}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={minusPaddingStyle2}>
                      {pollyVoices.map(name => (
                        <MenuItem onClick={() => setCurrentVoice(name)} key={Math.random()}>
                          {name}
                        </MenuItem>
                      ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={12} sm={4} md={4} style={{ fontWeight: 'bold' }}>
                  Click or drag file
                  <input id="myFile" style={{ height: 34, marginLeft: 0, marginTop: 10, paddingTop: 6, color: '#44b3b3' }}
                    type="file" name="file" accept=".csv" className="custom-file-input" onChange={fileUploaded} />
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={5}>
              <Row>
                <Col xs={3} md={4} xsOffset={0} mdOffset={4}>
                  <Row style={{ fontWeight: 'bold' }}> New CSV</Row>
                  <OverlayTrigger placement="top" overlay={saveAsCSVTooltip}>
                    <Button onClick={DownloadCSV} style={minusPaddingStyle1}>
                      Save as csv
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col xs={3} md={4} >
                  <Row style={{ fontWeight: 'bold' }}> Download all</Row>
                  <OverlayTrigger placement="top" overlay={downloadAllTooltip}>
                    <LoaderButton isLoading={processingTTS} onClick={downloadAllFilesAsZip} style={minusPaddingStyle1}>
                      Download
                    </LoaderButton>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
        </Panel>
        <ListGroup>
          {!isLoading && renderTTSForm()}
        </ListGroup>
      </div>
    );
  }

  return (
    <div>
      <div className="Home">
        {isAuthenticated ? renderTTS() : renderLander()}
      </div>
    </div>
  );
}
