import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem, Modal, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import Routes from "./Routes";
import "./App.css";
import log from 'loglevel';

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      log.disableAll();
    }
    catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  function showInstructionsModal(show) {
    setShowModal(show);
  }

  return (
    !isAuthenticating && (
      <div>
        <div className="App container">
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">Gemini</Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                {isAuthenticated ? (
                  <>
                    <NavItem eventKey={1} onClick={() => showInstructionsModal(true)}> Help </NavItem>
                    <NavDropdown eventKey={3} title="Documentation" id="basic-nav-dropdown">
                      <MenuItem eventKey={3.1} href="https://aws.amazon.com/polly/" target="_blank" rel="noopener noreferrer"
                      >AWS Polly docs</MenuItem>
                      <MenuItem divider />
                      <MenuItem eventKey={3.2} href="https://fundamentalsurgery.atlassian.net/wiki/spaces/ENG/pages/2659254273/Gemini" target="_blank" rel="noopener noreferrer"
                      >Confluence</MenuItem>
                      <MenuItem divider />
                      <MenuItem eventKey={3.3} href="https://www.spreadsheetclass.com/google-sheets-export-csv/"
                        target="_blank" rel="noopener noreferrer">Exporting .xls to .csv</MenuItem>
                    </NavDropdown>
                    <NavItem onClick={handleLogout}>Logout</NavItem>
                  </>
                ) : (
                  <>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <ErrorBoundary>
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              <Routes />
            </AppContext.Provider>
          </ErrorBoundary>
        </div>
        <Modal show={showModal}>
          <Modal.Header>
            <Modal.Title style={{ paddingLeft: 10, fontWeight: 'bold' }}> Instructions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li> Input a 2 column .csv file in the format of "File Name, Text to translate" or paste in lines manually. The first line of the file will be ignored. </li>
              <li>  The "Preview" button must be clicked in order to refresh the audio in the audio player,
                e.g on listening a translation in US-English and then changing the voice UK-English, preview must be clicked again to update this. </li>
              <li>  The "Update file" button is required to be pressed if you edit the file name text box, in order to save your changes </li>
              <li>  The "Clear Data" button will wipe all the current data you have, and clear the selected file from the picker. Warning: this cannot be undone. </li>
              <li>  There is a character limit of 3000 per entry to translate to speech. If an element in the list has more than this,
                you will get an alert warning you.</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => showInstructionsModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}

export default App;
